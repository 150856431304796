import loader from '../svg/loader.svg';

const companyName = document.querySelector('#companyName');
const name = document.querySelector('#name');
const surname = document.querySelector('#surname');
const email = document.querySelector('#email');
const title = document.querySelector('#title');
const sendBtn = document.querySelector('#sendBtn');
const cyberRiskForm = document.querySelector('#cyberRiskForm');

if (companyName !== null) {
  companyName.addEventListener('input', () => {
    if (companyName.value !== '' && name.value !== '' && email.value !== '' && surname.value !== '' && title.value !== '') {
      sendBtn.classList.add('active');
    } else {
      sendBtn.classList.remove('active');
    }
  });

  name.addEventListener('input', () => {
    if (companyName.value !== '' && name.value !== '' && email.value !== '' && surname.value !== '' && title.value !== '') {
      sendBtn.classList.add('active');
    } else {
      sendBtn.classList.remove('active');
    }
  });

  email.addEventListener('input', () => {
    if (companyName.value !== '' && name.value !== '' && email.value !== '' && surname.value !== '' && title.value !== '') {
      sendBtn.classList.add('active');
    } else {
      sendBtn.classList.remove('active');
    }
  });

  title.addEventListener('input', () => {
    if (companyName.value !== '' && name.value !== '' && email.value !== '' && surname.value !== '' && title.value !== '') {
      sendBtn.classList.add('active');
    } else {
      sendBtn.classList.remove('active');
    }
  });

  surname.addEventListener('input', () => {
    if (companyName.value !== '' && name.value !== '' && email.value !== '' && surname.value !== '' && title.value !== '') {
      sendBtn.classList.add('active');
    } else {
      sendBtn.classList.remove('active');
    }
  });

  cyberRiskForm.addEventListener('submit', function(event) {
    event.preventDefault();
    sendBtn.innerHTML = loader

    const formData = new FormData(this);

    fetch('https://ozcnzmcb8k.execute-api.eu-central-1.amazonaws.com/Prod/submit', {
      method: 'POST',
      body: JSON.stringify({
        FirstName: formData.get('name'),
        LastName: formData.get('surname'),
        Email: formData.get('email'),
        CompanyName: formData.get('companyName'),
        Title: formData.get('title')
      })
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Network response was not ok.');
      })
      .then(() => {
        sendBtn.innerHTML = 'sent ✔️'
      })
      .catch(error => {
        sendBtn.innerHTML = 'book a call'
      });
  });
}