import './animate-block'
import '../style/style.scss'
import './skrollr.min'
import './cyber-risk-form'
import loader from '../svg/loader.svg'

if (window.location.href === 'https://www.intangicmga.co/press' || window.location.href === 'https://www.intangicmga.com/press') {
  location.replace("https://www.intangicmga.co/resources/press/031623");
}

const observer = new IntersectionObserver(entries => {
  entries.forEach(entry => {
    if (!entry.isIntersecting) {
      document.querySelector('.scroll__nav').classList.add('scroll__nav-show')
    } else {
      document.querySelector('.scroll__nav').classList.remove('scroll__nav-show')
    }
  });
});

observer.observe(document.querySelector('.navigation'))

// menu btn

let menuBtn = document.querySelectorAll('.menu-btn');
let nav = document.querySelectorAll('.navigation');

let menuOpen = () => {
  menuBtn.forEach(e => {
    e.innerHTML = 'close';
  });
  nav.forEach(e => {
    e.style.maxHeight = '215px';
  });
}

let menuClose = () => {
  menuBtn.forEach(e => {
    e.innerHTML = 'menu';
  });
  nav.forEach(e => {
    e.style.maxHeight = '47px';
  });
}
menuBtn.forEach(btn => {
  btn.addEventListener('click', (e) => {
    if (e.currentTarget.innerHTML === 'menu') {
      menuOpen();
    } else {
      menuClose();
    }
  });
});

window.addEventListener('scroll', menuClose)

if (document.querySelector('.answer')) {
  const HIGHLIGHT = 'class="highlight"';
  const TEXT = "Answering the <br>Trillion Dollar question<br><br>In his 20 years as a hedge fund manager, CEO and Founder Ryan Dodd saw the explosion of digital transformation fundamentally change how companies created value<br><br>But when he asked the executive teams of the companies he was a shareholder of, “how are you validating how you are managing your technology (i.e. cyber) risk?”, he couldn’t get a good answer.<br><br>As a result, he ended up seeking answers with alternative datasets that could give him a solution, an informational edge. That process led to what became the data-science foundation for Intangic.<br><br>Giving customers this same informational edge on cyber risk is what drives the company today. "
  let words = TEXT.split(" ");
  let text = document.querySelector('.text');
  text.innerHTML = words
    .map(w => `<span>${w}</span>`)
    .join(' ');

  window.addEventListener('scroll', () => {
    let scroll = getScrollFraction();
    let words_highlighted = scroll * words.length;

    text.innerHTML = words
      .map((w, i) => `<span ${i < words_highlighted ? HIGHLIGHT : ''}>${w}</span>`)
      .join(' ');
  })

  function getScrollFraction() {
    let t = (window.scrollY - (document.querySelector('.answer').offsetTop - 200)) / (document.querySelector('.answer').scrollHeight - window.innerHeight)
    if (window.matchMedia("(min-width: 400px)").matches) {
      return t * 0.5;
    }
    return t * 0.6;
  }
}

const firstName = document.querySelector('#firstName');
const lastName = document.querySelector('#lastName');
const email = document.querySelector('#email');
const formButton = document.querySelector('#formButton');
const contactForm = document.querySelector('#contactForm');

if (firstName !== null) {
  firstName.addEventListener('input', () => {
    if (firstName.value !== '' && lastName.value !== '' && email.value !== '') {
      formButton.classList.add('active');
    } else {
      formButton.classList.remove('active');
    }
  });

  lastName.addEventListener('input', () => {
    if (firstName.value !== '' && lastName.value !== '' && email.value !== '') {
      formButton.classList.add('active');
    } else {
      formButton.classList.remove('active');
    }
  });

  email.addEventListener('input', () => {
    if (firstName.value !== '' && lastName.value !== '' && email.value !== '') {
      formButton.classList.add('active');
    } else {
      formButton.classList.remove('active');
    }
  });

  contactForm.addEventListener('submit', function(event) {
    event.preventDefault();
    formButton.innerHTML = loader

    const formData = new FormData(this);

    fetch('https://ozcnzmcb8k.execute-api.eu-central-1.amazonaws.com/Prod/submit', {
      method: 'POST',
      body: JSON.stringify({
        FirstName: formData.get('firstName'),
        LastName: formData.get('lastName'),
        Email: formData.get('email')
      })
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Network response was not ok.');
      })
      .then(() => {
        formButton.innerHTML = 'sent ✔️'
      })
      .catch(error => {
        formButton.innerHTML = 'send'
      });
  });
}

const resourcesNavLink = document.querySelectorAll('.resources__nav nav a');

const activeLink = (e) => {
  resourcesNavLink.forEach((el) => {
    el.classList.remove('active');
  })
  e.currentTarget.classList.add('active');
};

resourcesNavLink.forEach((el) => {
  el.addEventListener('click', activeLink);
})

const popupButtons = document.querySelectorAll('[data-popup-target]');
const closeButtons = document.querySelectorAll('[data-close-popup]');

popupButtons.forEach(button => {
  button.addEventListener('click', () => {
    const targetId = button.getAttribute('data-popup-target');
    const popup = document.getElementById(targetId);
    popup?.classList.add('show-form');
    popup?.removeAttribute('hidden');
  });
});

closeButtons.forEach(button => {
  button.addEventListener('click', () => {
    const popup = button.closest('.cyber__risk-form');
    popup?.classList.remove('show-form');
    popup?.setAttribute('hidden', '');
  });
});

document.addEventListener('click', function (event) {
  document.querySelectorAll('.cyber__risk-form.show-form').forEach(form => {
    const isClickInside = form.contains(event.target);
    const isPopupButton = [...popupButtons].some(btn => btn.contains(event.target));
    if (!isClickInside && !isPopupButton) {
      form.classList.remove('show-form');
      form.setAttribute('hidden', '');
    }
  });
});

const tabButtons = document.querySelectorAll('[data-tab-target]');
const tabContents = document.querySelectorAll('[data-tab-content]');

tabButtons.forEach(button => {
  button.addEventListener('click', () => {
    const targetId = button.getAttribute('data-tab-target');

    tabContents.forEach(content => {
      content.hidden = content.id !== targetId;
    });

    tabButtons.forEach(btn => {
      btn.classList.toggle('active', btn === button);
    });
  });
});